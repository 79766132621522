import { Container } from "@/shared/ui/container/Container";
import { EmailSvg } from "@/shared/svg/EmailSvg";
import { PhoneSvg } from "@/shared/svg/PhoneSvg";

import styles from "./LayoutFooter.module.scss";
import { Link } from "react-router-dom";


const LayoutFooter = () => {

  return (
    <footer className={styles.footer}>
      <div className={styles.filter}></div>
      <Container>
        <section className={styles.footerContent}>
        <div className={styles.leftSide}>
    <EmailSvg className={styles.emailSvg} />
    <a href={`mailto:giftoboom@gmail.com`} className={styles.footerItemText}>
    giftoboom@gmail.com
    </a>
        </div>
        <div className={styles.rightSide}>
        {/* <PhoneSvg className={styles.phoneSvg}/> */}
        <Link to={'https://t.me/giftoboom/'} target="_blank" className={styles.footerItemText}>
        https://t.me/giftoboom
        </Link>
        {/* <a href={`tel:+79193275627`} className={styles.footerItemText}>
        +7(919)327-56-27
    </a> */}
        </div>
        </section>
      </Container>
    </footer>
  );
};

export { LayoutFooter };
