import {  useRef, useState, ChangeEvent } from "react";

const useMediaFiles = () => {
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRefUpdate = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<
    { id: string; result: string | ArrayBuffer | null; file: File | null }
  >({ file: null, id: '', result: '' });


  const addFile = (result: string | ArrayBuffer, file: File) => {
    const id = String(Date.now());
    setFileList(() =>  ({ id, result: String(result), file }));
  };

  const editFile = ( result: string | ArrayBuffer, file: File) => {
    const id = String(Date.now());
    setFileList(() => ({
       id: String(id),
        file: file,
        result: String(result),
    }));
  };



  const setFileToBase =  (file: File, update?: boolean) => {
      try {
        const render = new FileReader();
        render.readAsDataURL(file);

        render.onloadend = () => {
          if (!render.result) return;

          if (update) {
            editFile(render.result, file);
          } else {
            addFile(render.result, file);
          }
        };
      } catch (error) {
        console.log(error, "Ошибка при загрузке файла!");
      }
    }

  const changeFile =  (e: ChangeEvent<HTMLInputElement> | any) => {
      const file = e.target.files ? e.target.files[0] : false;
      if (!file) return;
      setFileToBase(file, false);
    }


  function handleEditFile(e: ChangeEvent<HTMLInputElement> | any,) {
    const file = e.target.files ? e.target.files[0] : false;
    if (!file) return;
    setFileToBase(file, true);
  }

  function handleResetFile() {
    setFileList({ file: null, id: '', result: '' });
  }




  return {
    handleEditFile,
    fileRef,
    changeFile,
    fileList,
    fileRefUpdate,
    handleResetFile,
  };
};

export { useMediaFiles };
