import { AuthModel } from "../types";
import { processStore } from "./store";


export class ProcessAdapter {
 private store = processStore;

  constructor() {}

  public saveTemplateNods(nodes: string[]) {
    this.store.setState({ processNodes: nodes, step: 0, totalCount: nodes.length - 1 });
  }

  public resetProcess() {
    this.store.setState({ processNodes: [], step: 0, totalCount: 0 });
  }

  public updateAuth(auth: AuthModel) {
    this.store.setState({ auth });
  }

  public updateTokens({ accessToken, refreshToken }: { accessToken: string, refreshToken: string }) {
    const currentAuth = this.store.getState().auth;

    if (currentAuth) {
    this.store.setState({ auth: { ...currentAuth, refreshToken, accessToken } });
    }
  }

  public setSpeed(value: number) {
    this.store.setState({ speed: value });
  }

  public setPlay(value: boolean) {
    this.store.setState({ play: value });
  }

  public changeStep(value: number) {
    this.store.setState({ step: value });
  
  }

  public toggleMainInsturction(value: boolean) {
    this.store.setState({ isShowMainIstruction: value });
  }

  public toggleProcessInsturction(value: boolean) {
    this.store.setState({ isShowProcessIstruction: value });
  }

  public saveAuth(auth: AuthModel) {
    this.store.setState({ auth });
  }

  public getStepsVoiceStrings() {
    const nodesList = this.store.getState().processNodes;
    const step = this.store.getState().step;
    return { current: nodesList[step], next: nodesList[step + 1]};
  
  }
  public getCurrentAndPrevStep() {
    const nodesList = this.store.getState().processNodes;
    const step = this.store.getState().step;

    return { currentStep: nodesList[step], prevStep: nodesList[step -1] }
  }

  public getAdjacentSteps() {
    const step = this.store.getState().step;
    const nodesList = this.store.getState().processNodes;

    const adjacentResult: {prevSteps: string[], nextSteps: string[]} = {
      prevSteps: [],
      nextSteps: [],
    }

    for (let i = step - 4; i < nodesList.length; i++) {
      if (i > step + 4) break;
      if (i === step) continue;
      const element = nodesList[i];

      if (element && i < step) {
        adjacentResult.prevSteps.push(element)
      } else  if (!element && i < step) {
        adjacentResult.prevSteps.push('0')
      } else  if (element && i > step) {
        adjacentResult.nextSteps.push(element)
      }
    }

    return adjacentResult;
  }

  public searchStep(value: number) {
    const nodesList = this.store.getState().processNodes;
    const searchNode = nodesList[ value -1 ];

    if (searchNode) {
      this.store.setState({ step: value - 1 });
      return 'success';
    } else {
      return 'error';
    }
  }

  public getRefreshToken() {
    return this.store.getState().auth?.refreshToken;
  }


}
