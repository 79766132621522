const NitificationSuccessSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="23" fill="#26C656" />
      <path
        d="M30.104 16.955C30.2063 16.8506 30.3285 16.7676 30.4634 16.7109C30.5982 16.6543 30.743 16.6251 30.8892 16.6251C31.0355 16.6251 31.1803 16.6543 31.3151 16.7109C31.4499 16.7676 31.5721 16.8506 31.6745 16.955C32.1035 17.3885 32.1095 18.089 31.6895 18.53L22.82 29.015C22.7193 29.1256 22.5971 29.2145 22.4608 29.2762C22.3246 29.3379 22.1772 29.3712 22.0277 29.374C21.8781 29.3768 21.7296 29.3491 21.5912 29.2925C21.4527 29.236 21.3273 29.1517 21.2225 29.045L15.8255 23.576C15.6173 23.3638 15.5007 23.0783 15.5007 22.781C15.5007 22.4837 15.6173 22.1983 15.8255 21.986C15.9278 21.8816 16.05 21.7986 16.1849 21.7419C16.3197 21.6853 16.4645 21.6561 16.6107 21.6561C16.757 21.6561 16.9018 21.6853 17.0366 21.7419C17.1714 21.7986 17.2936 21.8816 17.396 21.986L21.974 26.6255L30.074 16.988L30.104 16.955Z"
        fill="white"
      />
      <path
        d="M23.0003 36.6803C30.5556 36.6803 36.6803 30.5556 36.6803 23.0003C36.6803 15.4451 30.5556 9.32031 23.0003 9.32031C15.4451 9.32031 9.32031 15.4451 9.32031 23.0003C9.32031 30.5556 15.4451 36.6803 23.0003 36.6803Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { NitificationSuccessSvg };
