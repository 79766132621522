const NotificationWarnSvg = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="23" fill="#007BCE" />
      <path
        d="M23.0003 36.6803C30.5556 36.6803 36.6803 30.5556 36.6803 23.0003C36.6803 15.4451 30.5556 9.32031 23.0003 9.32031C15.4451 9.32031 9.32031 15.4451 9.32031 23.0003C9.32031 30.5556 15.4451 36.6803 23.0003 36.6803Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 29.0801H23.0144V29.0945H23V29.0801Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M23 22.9999V16.9199"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { NotificationWarnSvg };
