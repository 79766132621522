import { useNavigate } from "react-router-dom";
import { Container } from "@/shared/ui/container/Container";
import HeaderLogo from '../../../../public/assets/images/logo.png'
// import { UserHeaderSvg } from "@/shared/svg/UserHeaderSvg";
// import { processStore } from "@/shared/service/proggress";

import styles from "./LayoutHeader.module.scss";


const LayoutHeader = () => {
  const navigate = useNavigate();
  // const processNodes = processStore(store => store.processNodes);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.blurContainer}></div>
        <Container>
          <section className={styles.content}>

        <img
               onClick={() => navigate('/')}
                className={styles.logoImg}
                src={HeaderLogo}
                alt="Header Logo"
                />

              {/* <UserHeaderSvg  onClick={() => processNodes.length > 0 && navigate('/process')} className={styles.userImg}/> */}
                </section>
        
        </Container>
      </header>
    </>
  );
};

export { LayoutHeader };
