const NotificationErrorSvg = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="23" fill="#BD3133" />
      <path
        d="M15.9584 15.0803C15.7255 15.0803 15.5021 15.1728 15.3373 15.3373C15.1728 15.5021 15.0803 15.7255 15.0803 15.9584C15.0803 16.1913 15.1728 16.4146 15.3373 16.5794L15.9584 15.0803ZM15.9584 15.0803C16.1913 15.0803 16.4146 15.1728 16.5794 15.3373L15.9584 15.0803ZM15.2667 15.2666L15.2666 15.2667C15.0833 15.4502 14.9803 15.699 14.9803 15.9584C14.9803 16.2178 15.0833 16.4665 15.2666 16.6501L15.2666 16.6501L29.3266 30.7101C29.416 30.8057 29.5236 30.8823 29.6431 30.9356C29.7632 30.9891 29.8928 31.0179 30.0242 31.0202C30.1557 31.0225 30.2862 30.9983 30.4081 30.9491C30.53 30.8999 30.6407 30.8266 30.7336 30.7336C30.8266 30.6407 30.8999 30.53 30.9491 30.4081C30.9983 30.2862 31.0225 30.1557 31.0202 30.0242C31.0179 29.8928 30.9891 29.7632 30.9356 29.6431C30.8823 29.5236 30.8057 29.416 30.7101 29.3266L16.6501 15.2666L16.6501 15.2666C16.4665 15.0833 16.2178 14.9803 15.9584 14.9803C15.699 14.9803 15.4502 15.0833 15.2667 15.2666Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M30.7107 15.2667L30.7106 15.2666C30.5271 15.0833 30.2783 14.9803 30.0189 14.9803C29.7595 14.9803 29.5108 15.0833 29.3272 15.2666L29.3272 15.2666L15.266 29.3278L15.2659 29.3278L15.2635 29.3304C15.0906 29.5159 14.9965 29.7613 15.001 30.0149C15.0055 30.2685 15.1082 30.5105 15.2875 30.6898C15.4669 30.8691 15.7088 30.9719 15.9624 30.9763C16.216 30.9808 16.4614 30.8867 16.6469 30.7138L16.647 30.7138L16.6495 30.7113L30.7107 16.6501L30.7107 16.6501C30.894 16.4665 30.997 16.2178 30.997 15.9584C30.997 15.699 30.894 15.4502 30.7107 15.2667Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M23.0003 36.6803C30.5556 36.6803 36.6803 30.5556 36.6803 23.0003C36.6803 15.4451 30.5556 9.32031 23.0003 9.32031C15.4451 9.32031 9.32031 15.4451 9.32031 23.0003C9.32031 30.5556 15.4451 36.6803 23.0003 36.6803Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { NotificationErrorSvg };
