import { useEffect } from "react";
import { notificationService, notificationStore } from "@/shared";

import styles from "./NotificationList.module.scss";
import { NitificationSuccessSvg } from "@/shared/svg/NitificationSuccessSvg";
import { CloseSvg } from "@/shared/svg/CloseSvg";
import { NotificationErrorSvg } from "@/shared/svg/NotificationErrorSvg";
import { NotificationWarnSvg } from "@/shared/svg/NotificationWarnSvg";

const NotificationList = () => {
  const notificationList = notificationStore((state) => state.notificationList);
  const timeOut = notificationStore((state) => state.timeOut);

  const checkClearNotification = () => {
    const currentDate = Date.now();
    const maxTimeNow = timeOut * 1000;

    for (let i = 0; i < notificationList.length; i++) {
      const notDateString = notificationList[i].timeFrom;
      const notNowDate = new Date(notDateString).getTime();

      if (currentDate - notNowDate >= maxTimeNow) {
        notificationService.resetNotification();
      }
    }
  };

  useEffect(() => {
    if (notificationList.length === 0) return;

    const timeOut = setInterval(checkClearNotification, 1000);

    return () => {
      clearInterval(timeOut);
    };
  }, [notificationList, timeOut]);

  const handleClose = () => {
    notificationService.resetNotification();
  }


  if (notificationList.length === 0) {
    return null;
  }

  return (
    <ul className={styles.wrapper}>
      {notificationList.map((notification, index) => (
        <li
          key={`${index}${notification.timeFrom}`}
          className={styles.root}
        >
          <div className={styles.svg}>
            {notification.status === 'success' &&
          <NitificationSuccessSvg/>
            }
            {notification.status === 'error' &&
          <NotificationErrorSvg/>
            }
            {notification.status === 'warn' &&
          <NotificationWarnSvg/>
            }
          </div>
          <div className={styles.content}>
          <h1 className={styles.notificationTitle}>
            {notification.status === 'success' &&
            'Отлично'
            }
            {notification.status === 'error' &&
            'Ошибка'
            }
            {notification.status === 'warn' &&
            'Предупреждение'
            }
            </h1>
          <span className={styles.notificationText}>
            {notification.message}
          </span>
          </div>
          <button onClick={handleClose} className={styles.closeSvg}>
          <CloseSvg />
          </button>
        </li>
      ))}
    </ul>
  );
};

export { NotificationList };
