import { createStore } from "../../create-store";
import type { ProcessInitState } from "../types";

const initState: ProcessInitState = {
  processNodes: [],
  step: 0,
  totalCount: 0,
  speed: 1,
  play: false,
  auth: null,
  isShowMainIstruction: true,
  isShowProcessIstruction: true,
};


export const processStore = createStore({
  initState,
  persistName: 'process',
});
