const EmailSvg = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 36 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.125 0.25H29.875C31.3668 0.25 32.7976 0.842632 33.8525 1.89752C34.9074 2.95242 35.5 4.38316 35.5 5.875V22.125C35.5 23.6168 34.9074 25.0476 33.8525 26.1025C32.7976 27.1574 31.3668 27.75 29.875 27.75H6.125C4.63316 27.75 3.20242 27.1574 2.14752 26.1025C1.09263 25.0476 0.5 23.6168 0.5 22.125V5.875C0.5 4.38316 1.09263 2.95242 2.14752 1.89752C3.20242 0.842632 4.63316 0.25 6.125 0.25ZM34.25 6.7575V5.875C34.25 4.71468 33.7891 3.60188 32.9686 2.78141C32.1481 1.96094 31.0353 1.5 29.875 1.5H6.125C4.96468 1.5 3.85188 1.96094 3.03141 2.78141C2.21094 3.60188 1.75 4.71468 1.75 5.875V6.7575L18 15.785L34.25 6.7575ZM1.75 8.1875V22.125C1.75 23.2853 2.21094 24.3981 3.03141 25.2186C3.85188 26.0391 4.96468 26.5 6.125 26.5H29.875C31.0353 26.5 32.1481 26.0391 32.9686 25.2186C33.7891 24.3981 34.25 23.2853 34.25 22.125V8.1875L18.3037 17.0462C18.2108 17.0979 18.1063 17.125 18 17.125C17.8937 17.125 17.7892 17.0979 17.6963 17.0462L1.75 8.1875Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export { EmailSvg };
