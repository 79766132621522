import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { LayoutHeader } from "../layout-header/LayoutHeader";
import { NotificationList } from "@/widgets/notification";
import { LayoutFooter } from "../layout-footer/LayoutFooter";
import { fetchService, layoutController, notificationService } from "@/shared";
import backgroundMain from '../../../../public/assets/images/main-background.png';
import backgroundMainMobile from '../../../../public/assets/images/main-background-modile.png';

import styles from "./MainLayout.module.scss";


const MainLayout = () => {
  // const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {

  }, []);

  // if (isLoading) {
  //   return;
  // }

  return (
    <main className={styles.root}>
        <img className={styles.backgroundMain} src={backgroundMain} alt="Background" />
        <div className={styles.imgWrapperMobile}>
        <img className={styles.backgroundMainMobile} src={backgroundMainMobile} alt="Background" />
        </div>
      <LayoutHeader  />
      <NotificationList />
      <div className={styles.content}>
      <Outlet />
      </div>
      <LayoutFooter />
    </main>
  );
};

export { MainLayout };
